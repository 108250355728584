import type {
  AnchorDirection,
  CatalogId,
  CatalogType,
  GravityRegion,
  ImageFormat,
  Macro,
  MergeComposite,
} from './types.js';

function quoteWrap(s: string) {
  return `"${s}"`;
}

const baseUrl = 'https://i.iheart.com';

interface MediaServerURLOptions {
  ops?: string[];
  url: URL;
}

function toURL(x: string) {
  if (URL.canParse(x)) {
    return new URL(x);
  } else {
    throw new TypeError(`Cannot create URL from: "${x}"`);
  }
}

export class MediaServerURL {
  url: URL;
  ops: string[];

  static fromURL(url: MediaServerURL | URL | string): MediaServerURL {
    if (url instanceof MediaServerURL) {
      return url.clone();
    }

    if (typeof url === 'string') {
      url = toURL(url);
    }

    if (!(url instanceof URL)) {
      console.trace('bad url');
      throw new TypeError(`Cannot create MediaServerURL from "${url}"`);
    }

    // External URL
    if (
      url?.hostname &&
      url.hostname !== 'i.iheart.com' &&
      url.hostname !== 'i-stg.iheart.com'
    ) {
      const base64Encoded = btoa(url.toString());
      url = new URL(['v3', 'url', base64Encoded].join('/'), baseUrl);
    }

    return new MediaServerURL({ url });
  }

  static fromCatalog({ type, id }: { type: CatalogType; id: CatalogId }) {
    const url = new URL(['v3', 'catalog', type, id].join('/'), baseUrl);
    return new MediaServerURL({ url });
  }

  static forUser({ id }: { id: number }) {
    const url = new URL(['v3', 'user', id, 'profile'].join('/'), baseUrl);
    return new MediaServerURL({ url });
  }

  constructor({ ops = [], url }: MediaServerURLOptions) {
    this.ops = ops;
    this.url = new URL(url);

    if (url.searchParams.has('ops')) {
      const existingOps = decodeURIComponent(url.searchParams.get('ops')!);
      const parsedOps = existingOps.split('),').map(x => {
        const [key, value] = x.split('(');
        return `${key}(${value.endsWith(')') ? value.slice(0, -1) : value})`;
      });
      this.ops = [...parsedOps, ...ops];
      this.url.searchParams.delete('ops');
    }
  }

  /** Convert the `MediaServerURL` to a `URL`. All ops will be applied to the URL before it is converted. */
  toURL() {
    if (this.ops.length > 0) {
      this.url.searchParams.set('ops', this.ops.join(','));
    }
    return this.url;
  }

  /** Convert the `MediaServerURL` to a string. All ops will be applied to the URL before it is converted. */
  toString() {
    if (this.ops.length > 0) {
      this.url.searchParams.set('ops', this.ops.join(','));
    }
    return this.url.toString();
  }

  /**
   * Clone the `MediaServerURL` instance.
   *
   * You can use this to do things like applying some ops to a URL, clone it, and apply additional ops to each separately.
   */
  clone() {
    return new MediaServerURL({
      ops: [...this.ops],
      url: new URL(this.url),
    });
  }

  /**
   * Remove all existing ops.
   */
  clear() {
    this.ops = [];
    return this;
  }

  /**
   * Resize image exactly as specified.
   *
   * When one dimension is 0, the aspect ratio is locked and the image is scaled only based on the dimension specified.
   */
  resize(width: number = 0, height: number = 0) {
    this.ops.push(`resize(${[width, height].join(',')})`);
    return this;
  }

  /**
   * Alias of `resize()`.
   *
   * Resize image exactly as specified.
   *
   * When one dimension is 0, the aspect ratio is locked and the image is scaled only based on the dimension specified.
   */
  scale(width: number = 0, height: number = 0) {
    this.ops.push(`scale(${[width, height].join(',')})`);
    return this;
  }

  resizei(width: number = 0, height: number = 0) {
    this.ops.push(`resizei(${[width, height].join(',')})`);
    return this;
  }

  box(
    width: number = 0,
    height: number = 0,
    crop: boolean = true,
    upscale: boolean = true,
    pad: boolean = true,
  ) {
    this.ops.push(`box(${[width, height, crop, upscale, pad].join(',')})`);
    return this;
  }
  /**
   * Crops an image to fill the entire box you specify.
   *
   * It is highly recommended to use `gravity()` and/or `anchor()` in conjunction with `cover()` / `fit()` to ensure the important content of the image is visible.
   */
  cover(width: number = 0, height: number = 0) {
    this.ops.push(`cover(${[width, height].join(',')})`);
    return this;
  }

  /**
   * Alias of `cover()`
   *
   * Crops an image to fill the entire box you specify.
   *
   * It is highly recommended to use `gravity()` and/or `anchor()` in conjunction with `cover()` / `fit()` to ensure the important content of the image is visible.
   */
  fit(width: number = 0, height: number = 0) {
    this.ops.push(`fit(${[width, height].join(',')})`);
    return this;
  }

  /**
   * Fills the specified space as best as it can without losing any image data at all.
   *
   * Any extra space will be filled with content-aware color.
   */
  contain(width: number = 0, height: number = 0) {
    this.ops.push(`contain(${[width, height].join(',')})`);
    return this;
  }

  fitwithin(width: number = 0, height: number = 0) {
    this.ops.push(`fitwithin(${[width, height].join(',')})`);
    return this;
  }

  max(width: number = 0, height: number = 0) {
    this.ops.push(`max(${[width, height].join(',')})`);
    return this;
  }

  maxcontain(width: number = 0, height: number = 0) {
    this.ops.push(`maxcontain(${[width, height].join(',')})`);
    return this;
  }

  crop(width: number = 0, height: number = 0) {
    this.ops.push(`crop(${[width, height].join(',')})`);
    return this;
  }

  cropi(width: number = 0, height: number = 0) {
    this.ops.push(`cropi(${[width, height].join(',')})`);
    return this;
  }

  cropexact(x: number, y: number, width: number = 0, height: number = 0) {
    this.ops.push(`cropexact(${[x, y, width, height].join(',')})`);
    return this;
  }

  /**
   * Specify the aspect ratio of the image.
   */
  ratio(horizontal: number, vertical: number) {
    this.ops.push(`ratio(${[horizontal, vertical].join(',')})`);
    return this;
  }

  blur(radius: number) {
    this.ops.push(`blur(${radius})`);
    return this;
  }

  grey() {
    this.ops.push(`grey()`);
    return this;
  }

  bc(brightness: number, contrast: number) {
    this.ops.push(`bc(${[brightness, contrast].join(',')})`);
    return this;
  }

  duotone(color1: string, color2: string) {
    this.ops.push(`duotone(${[color1, color2].map(quoteWrap).join(',')})`);
    return this;
  }

  flood(color: string) {
    this.ops.push(`flood(${quoteWrap(color)})`);
    return this;
  }

  gradient2(color1: string, color2: string, rotation: number) {
    this.ops.push(
      `gradient2(${quoteWrap(color1)},${quoteWrap(color2)},${rotation})`,
    );
    return this;
  }

  gravity(region: GravityRegion) {
    this.ops.push(`gravity(${quoteWrap(region)})`);
    return this;
  }

  anchor(x: number, y: number) {
    this.ops.push(`anchor(${[x, y].join(',')})`);
    return this;
  }

  smush() {
    this.ops.push('smush()');
    return this;
  }

  dup() {
    this.ops.push('dup()');
    return this;
  }

  swap() {
    this.ops.push('swap()');
    return this;
  }

  new() {
    this.ops.push('new()');
    return this;
  }

  merge(composite: MergeComposite) {
    this.ops.push(`merge(${quoteWrap(composite)})`);
    return this;
  }

  boxmerge(
    composite: MergeComposite,
    anchors: Partial<Record<AnchorDirection, string>>,
  ) {
    const stringifiedAnchors = Object.entries(anchors)
      .map(([k, v]) => k + ':' + v)
      .join(',');
    this.ops.push(
      `boxmerge(${[quoteWrap(composite), quoteWrap(stringifiedAnchors)].join(
        ',',
      )})`,
    );
    return this;
  }

  pluck(index: number) {
    this.ops.push(`pluck(${index})`);
    return this;
  }

  tile(cols: number, rows: number) {
    this.ops.push(`tile(${[cols, rows].join(',')})`);
    return this;
  }

  grid(
    cols: number,
    rows: number,
    offset: number,
    padding: number,
    gap: number,
  ) {
    this.ops.push(`grid(${[cols, rows, offset, padding, gap].join(',')})`);
    return this;
  }

  format(...formats: ImageFormat[]) {
    this.ops.push(`format(${formats.map(quoteWrap).join(',')})`);
    return this;
  }

  /**
   * Set the image quality for codecs that support it. For lossless codecs this command will do nothing.
   */
  quality(value: number) {
    this.ops.push(`quality(${value})`);
    return this;
  }

  run(macro: Macro) {
    this.ops.push(`run(${quoteWrap(macro)})`);
    return this;
  }
}
