import {
  type CSSProperties,
  type ImgHTMLAttributes,
  type ReactNode,
  forwardRef,
  useState,
} from 'react';
import srcset from 'srcset';

import { MediaServerURL } from './media-server-url.js';

type DefaultImageAttributes = ImgHTMLAttributes<HTMLImageElement>;

type ImageFormat = 'webp' | 'png' | 'jpeg' | 'gif';

export interface ImageLoaderFunctionArgs {
  density: number;
  src: string | URL | MediaServerURL;
  height?: number;
  width?: number;
  quality: number;
  format: ImageFormat;
}

export type ImageLoaderFunction = (args: ImageLoaderFunctionArgs) => string;

export interface ImageProps {
  /**
   * The alt text for the image.
   */
  alt: string;

  className?: string | undefined;
  /**
   * The densities to define for the image. This is used to generate the `srcset` attribute for
   * various pixel densities.
   * @default [1, 2]
   */
  densities?: Array<number>;
  /**
   * The widths to define for the image. This is used to generate the `srcset` attribute for various widths. Must define `sizes` if this is defined.
   */
  widths?: Array<number>;
  /**
   * The sizes to define for the image. This is used to generate the `sizes` attribute. Must be
   * defined if `widths` is defined.
   */
  sizes?: DefaultImageAttributes['sizes'] | undefined;
  /**
   * The height of the image. Passed to the underlying `height` attribute.
   */
  height?: number;
  /**
   * The height of the image. Passed to the underlying `width` attribute.
   */
  width?: number;
  /**
   * The format of the image file to load.
   * @default webp
   */
  format?: ImageFormat;
  /**
   * Define custom loading functionality for the image.
   * @default defaultImageLoader
   */
  loader?: ImageLoaderFunction;
  /**
   * Define custom loading functionality for the placeholder image.
   * @default defaultPlaceholderLoader
   */
  placeholderLoader?: ImageLoaderFunction;
  /**
   * Whether or not to render a placeholder image.
   * - If `false` or `undefined`, no placeholder will be used.
   * - If `true`, the placeholder will be created using the `placeholderLoader` function.
   * - If a `string`, the value will be used as the placeholder image source, bypassing the `placeholderLoader` function.
   * - If a `ReactNode`, the value will be rendered as the placeholder.
   */
  placeholder?: ReactNode;
  /**
   * The source of the image.
   */
  src: string | URL | MediaServerURL;
  /**
   * The quality to use for the image. Specifying this value will add a `quality` parameter to the MediaServerURL.
   * @default 75
   */
  quality?: number;
}

function isNil(x: unknown): x is null | undefined {
  return x == null;
}

export function defaultImageLoader(options: ImageLoaderFunctionArgs) {
  const { src, width, height, density = 1, quality, format } = options;
  const url = MediaServerURL.fromURL(src).format(format).quality(quality);

  const finalWidth =
    !isNil(width) && !Number.isNaN(width) ? width * density : 0;
  const finalHeight =
    !isNil(height) && !Number.isNaN(height) ? height * density : 0;

  url.fit(finalWidth, finalHeight);

  return url.toString();
}

export function defaultPlaceholderLoader(options: ImageLoaderFunctionArgs) {
  const { src, format } = options;
  return MediaServerURL.fromURL(src)
    .format(format)
    .quality(1)
    .fit(100, 0)
    .toString();
}

/**
 * A component for rendering images with support for responsive images, lazy loading, and
 * placeholders. This component is a wrapper around the native `<img>` element.
 */
export const Image = forwardRef<HTMLImageElement, ImageProps>(function Image(
  props: ImageProps,
  ref,
) {
  const {
    alt,
    src,
    format = 'webp',
    densities = [],
    widths = [],
    sizes,
    loader = defaultImageLoader,
    placeholder,
    placeholderLoader,
    width,
    height,
    quality = 75,
    className,
    ...restProps
  } = props;

  if (densities.length > 0 && widths.length > 0) {
    throw new Error('cannot specify both "densities" and "widths"');
  }

  if (widths.length > 0 && !sizes) {
    throw new Error('if declaring "widths", "sizes" must also be declared');
  }

  const placeholderSource =
    placeholder && typeof placeholder === 'string' ? placeholder
    : placeholder === true ?
      (placeholderLoader ?? defaultPlaceholderLoader)({
        density: 1,
        width,
        height,
        src,
        format,
        quality,
      })
    : null;

  const finalSource = loader({
    width,
    format,
    quality,
    height,
    density: 1,
    src,
  });

  const sourceSetDefinitions =
    widths.length > 0 ?
      [...widths, width].sort().map(width => {
        return {
          url: loader({ density: 1, width, height: 0, src, format, quality }),
          width,
        };
      })
    : (densities ?? [1, 2]).sort().map(density => {
        return {
          url: loader({ density, width, src, height, format, quality }),
          density,
        };
      });

  const sourceSet = srcset.stringify(sourceSetDefinitions);

  const { handleImageOnLoad, css } = useImageOnLoad();

  const style: { [key: string]: CSSProperties } = {
    wrap: {
      position: 'relative',
      width: '100%',
      height: 'auto',
      overflow: 'hidden',
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 'auto',
    },
  };

  if (placeholderSource) {
    return (
      <div className={className} style={style.wrap}>
        <img
          alt={`${alt} (thumbnail)`}
          className={className}
          data-test="img2-placeholder"
          decoding="async"
          loading="lazy"
          src={placeholderSource}
          style={{ ...style.image, ...(css.thumbnail as CSSProperties) }}
        />
        <img
          decoding="async"
          loading="lazy"
          ref={ref}
          {...restProps}
          alt={alt}
          className={className}
          data-test="img2"
          onLoad={handleImageOnLoad}
          sizes={sizes}
          src={finalSource}
          srcSet={sourceSet}
          style={{ ...style.image, ...(css.fullSize as CSSProperties) }}
        />
      </div>
    );
  }

  return (
    <img
      decoding="async"
      loading="lazy"
      ref={ref}
      {...restProps}
      alt={alt}
      className={className}
      data-test="img2"
      height={height}
      sizes={sizes}
      src={finalSource}
      srcSet={sourceSet}
      width={width}
    />
  );
});

interface ImageStyle {
  thumbnail: CSSProperties;
  fullSize: CSSProperties;
}

interface ImageOnLoadType {
  handleImageOnLoad: () => void;
  css: ImageStyle;
}

export function useImageOnLoad(): ImageOnLoadType {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Triggered when full image will be loaded.
  const handleImageOnLoad = () => {
    setIsLoaded(true);
  };

  const css: ImageStyle = {
    // Thumbnail style.
    thumbnail: {
      visibility: isLoaded ? 'hidden' : 'visible',
      filter: 'blur(8px)',
      transition: 'visibility 0ms ease-out 500ms',
    },
    // Full image style.
    fullSize: {
      opacity: isLoaded ? 1 : 0,
      transition: 'opacity 500ms ease-in 0ms',
    },
  };

  return { handleImageOnLoad, css };
}
